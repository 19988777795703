import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { signUpUser } from 'routes/routes';
import Loader from 'UI/components/loader/loader.component';
import { useDomainUseCase } from 'UI/contexts';

function PrivateRoute({ children }) {
  const getCurrentUser = useDomainUseCase('getCurrentUserUseCase');
  const [isAuthenticated, setAuthenticated] = useState(null);

  const checkCurrentUser = useCallback(async () => {
    const currentUser = await getCurrentUser();
    setAuthenticated(!!currentUser);
  }, [getCurrentUser]);

  useEffect(() => {
    checkCurrentUser()
      .then(() => {})
      .catch(error => Sentry.captureException(error));
  }, [checkCurrentUser]);

  if (isAuthenticated === null) return <Loader />;
  if (!isAuthenticated) return <Redirect from="*" to={signUpUser} />;
  return children;
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired
};

export function protect(Component) {
  return () => (
    <PrivateRoute>
      <Component />
    </PrivateRoute>
  );
}

export default { PrivateRoute };
