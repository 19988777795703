import SignUpCompany from 'containers/sign-up/sign-up-company/sign-up-company.container';
import SignUpUser from 'containers/sign-up/sign-up-user/sign-up-user.container';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { protect } from 'UI/decorators';
import { CompanyIdGuard } from './guards/company-id.guard';
import * as ROUTES from './routes';

const AppRouter = () => {
  return (
    <Switch>
      <Route path={ROUTES.signUpCompany} component={SignUpCompany} />
      <Route path={ROUTES.signUpUser} component={SignUpUser} />
      <Route path={ROUTES.companyIdGuard} component={protect(CompanyIdGuard)} />
      <Redirect to={ROUTES.companyIdGuard} />
    </Switch>
  );
};

export default AppRouter;
