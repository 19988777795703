export const blue = {
  50: '#D6E8FF',
  500: '#0055FF',
  900: '#132C67'
};

export const brand = {
  100: '#00BAFF'
};

export const green = {
  50: '#D2FFDF',
  500: '#1C9542',
  900: '#2E5A1C'
};

export const red = {
  50: '#FFDBDB',
  500: '#C10404',
  900: '#851010'
};

export const yellow = {
  50: '#FDF9E6',
  500: '#EEBB02',
  900: '#806A00'
};

export const grey = {
  50: '#F2F2F2',
  100: '#CCCCCC',
  200: '#808080',
  300: '#333333',
  500: '#1A1A1A',
  900: '#0D0D0D'
};

export const root = {
  0: '#FFFFFF',
  900: '#000000'
};

export default { blue, brand, grey, green, red, root, yellow };
