import { makeStyles } from '@mui/styles';

const calcPaddingTop = () => {
  const safeAreaInsetTop = sessionStorage.getItem('safeAreaInsetTop');
  if (!safeAreaInsetTop || safeAreaInsetTop === '0px') {
    return '40px';
  }
  return `calc(${safeAreaInsetTop} + 16px)`;
};

const useStyles = makeStyles(() => ({
  header: {
    position: 'sticky',
    top: 0,
    zIndex: 10,
    display: 'flex',
    '-webkit-flex': '0 0 auto', // fix for safari bug on iOS 12
    justifyContent: 'space-between',
    paddingTop: calcPaddingTop(),
    paddingBottom: '16px',
    paddingLeft: '24px',
    paddingRight: '24px',
    backgroundColor: props => props.backgroundColor,
    borderBottom: props => `1px solid ${props.borderBottomColor}`,
    color: props => props.color
  }
}));

export default useStyles;
