import { useAmplifyAuth } from '@loggi/authentication-lib';
import { Box, useMediaQuery } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useSnackbar } from 'hooks';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import companiesAccountApi from 'service/companies-account-api';
import SignUpCompanyUI from 'UI/pages/sign-up/company/sign-up-company.component';

const CPF_ALREADY_EXISTS_API_ERROR = 'empresa com este cpf já existe.';
const CPF_REGISTERED_WITH_ANOTHER_EMAIL_API_ERROR =
  'CPFRegisteredForAnotherEmail: CPF is already registered for another email';
const DRIVER_NOT_ALLOWED_TO_CREATE_COMPANY_API_ERROR =
  'Driver user not allowed to create a company.';
const IRREGULAR_CPF = 'This field must be a valid CPF.';
const IRREGULAR_CPF_SHARED_NAME =
  'The name informed has not similar with the name registered.';
const CPF_DOES_NOT_MATCH =
  'Created company and existing user CPFs did not match.';
const EMAIL_DOES_NOT_MATCH_CODE = 'emailDoesNotMatchError';

const SignUpCompany = () => {
  const { t } = useTranslation('containers');
  const isDesktop = useMediaQuery('(min-width:600px)');
  const history = useHistory();
  const showSnackbar = useSnackbar();
  const { state, fetchCurrentUserFromCognito, signOut } = useAmplifyAuth();
  const signupError = state.error;

  const handleOnGoBackClick = useCallback(
    () => signOut().then(() => history.push('/')),
    [signOut, history]
  );

  const getKnownErrorsMessageFromJSON = message => {
    try {
      const errorObject = JSON.parse(message);
      if (errorObject) {
        // eslint-disable-next-line camelcase
        const [errorMessage] = errorObject.cpf || errorObject.non_field_errors;
        return errorMessage;
      }
      return null;
    } catch {
      return null;
    }
  };

  const getErrorMessage = useCallback(
    error => {
      const errorMessage = getKnownErrorsMessageFromJSON(error.message);
      if (
        errorMessage === CPF_ALREADY_EXISTS_API_ERROR ||
        errorMessage === CPF_REGISTERED_WITH_ANOTHER_EMAIL_API_ERROR ||
        error.message?.includes(EMAIL_DOES_NOT_MATCH_CODE)
      )
        return t('signUp.errorMessages.cpfAlreadyExists');
      if (
        errorMessage === IRREGULAR_CPF ||
        errorMessage === IRREGULAR_CPF_SHARED_NAME ||
        errorMessage?.toLowerCase().includes('bigdatacorp')
      )
        return t('signUp.errorMessages.cpfIrregular');
      if (errorMessage === DRIVER_NOT_ALLOWED_TO_CREATE_COMPANY_API_ERROR)
        return t('signUp.errorMessages.driverNotAllowed');
      if (error.message === CPF_DOES_NOT_MATCH)
        return t('signUp.errorMessages.cpfDoesNotMatch');
      if (error.status === 500) {
        return t('signUp.errorMessages.error500');
      }
      Sentry.captureException(error);
      return t('signUp.errorMessages.genericError', { detail: error.message });
    },
    [t]
  );

  const handleSignUpCompany = async companyData => {
    try {
      await companiesAccountApi.signupCompany(companyData);
      fetchCurrentUserFromCognito();
    } catch (error) {
      showSnackbar({ message: getErrorMessage(error), severity: 'error' });
      throw error;
    }
  };

  useEffect(() => {
    if (signupError)
      showSnackbar({
        message: getErrorMessage(signupError),
        severity: 'error'
      });
  }, [signupError, getErrorMessage, showSnackbar]);

  return (
    <Box
      style={{ height: '100vh' }}
      display={isDesktop ? 'flex' : 'auto'}
      alignItems="center"
    >
      <SignUpCompanyUI
        onGoBack={handleOnGoBackClick}
        onSubmit={handleSignUpCompany}
      />
    </Box>
  );
};

export default SignUpCompany;
