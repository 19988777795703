import * as Sentry from '@sentry/react';
import React, { useCallback, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { companyIdGuard, signUpCompany } from 'routes/routes';
import Loader from 'UI/components/loader/loader.component';
import { useDomainUseCase } from 'UI/contexts';
import { fillCompanyId } from 'utils';

export const CompanyIdGuard = () => {
  const [loading, setLoading] = useState(true);
  const [companyId, setCompanyId] = useState(null);
  const getCurrentUser = useDomainUseCase('getCurrentUserUseCase');

  const getUserInfos = useCallback(async () => {
    const currentUser = await getCurrentUser();
    setCompanyId(currentUser?.companyId);
    setLoading(false);
  }, [getCurrentUser]);

  useEffect(() => {
    getUserInfos()
      .then(() => {})
      .catch(error => Sentry.captureException(error));
  }, [getUserInfos]);

  if (loading) return <Loader />;
  if (!companyId) return <Redirect from="*" to={signUpCompany} />;
  return (
    <Redirect
      from="*"
      to={fillCompanyId({ companyId, route: companyIdGuard })}
    />
  );
};

export default CompanyIdGuard;
