// Sentry encodes the / character that we use on our tags,
// so we need to replace them.
//
// Ex: "single-signup/stg-20210316.02/beta" becomes "single-signup-stg-20210316.02-beta"
const tag = process.env.REACT_APP_VERSION;
const formattedSentryRelease = tag.replace(/\//g, '-');

const sentryOptions = {
  dsn: process.env.REACT_APP_DSN_SENTRY,
  environment: process.env.REACT_APP_ENV_SENTRY,
  normalizeDepth: 10,
  release: formattedSentryRelease
};

export default sentryOptions;
