import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'UI/components';
import { blue, root, grey } from 'theme/colors';
import { useUserRecords } from 'UI/contexts';

const SignUpModalityForm = ({ handleOnNextStep }) => {
  const { t } = useTranslation('ui');
  const { userRecords, setUserRecords } = useUserRecords();
  const [isCNPJSignUp, setIsCNPJSignUp] = useState(false);
  const [isCPFSignUp, setIsCPFSignUp] = useState(false);

  const handleSubmit = () => {
    setUserRecords({
      ...userRecords,
      isNaturalPersonSignUp: isCPFSignUp
    });
    handleOnNextStep();
  };

  return (
    <Box p={3}>
      <Typography fontWeight="fontWeightMedium" variant="subtitle1">
        {t('signUpUser.modalityForm.title')}
      </Typography>
      <Typography
        fontWeight="fontWeightMedium"
        color={grey[200]}
        variant="body2"
      >
        {t('signUpUser.modalityForm.subtitle')}
      </Typography>
      <Box display="flex" py={3}>
        <Box mr={1} flex={1}>
          <Button
            bgColor={root[0]}
            borderColor={isCPFSignUp ? blue[500] : grey[50]}
            borderWidth={2}
            fullWidth
            label={t('signUpUser.modalityForm.optionLabel.cpf')}
            fontWeight={500}
            textColor={root[900]}
            variant="outlined"
            onClick={() => {
              setIsCPFSignUp(true);
              setIsCNPJSignUp(false);
            }}
          />
        </Box>
        <Box flex={1}>
          <Button
            bgColor={root[0]}
            borderColor={isCNPJSignUp ? blue[500] : grey[50]}
            borderWidth={2}
            fullWidth
            label={t('signUpUser.modalityForm.optionLabel.cnpj')}
            fontWeight={500}
            textColor={root[900]}
            variant="outlined"
            onClick={() => {
              setIsCPFSignUp(false);
              setIsCNPJSignUp(true);
            }}
          />
        </Box>
      </Box>
      <Box>
        <Button
          fullWidth
          disabled={!isCPFSignUp && !isCNPJSignUp}
          label={t('signUpUser.submitButton.label')}
          onClick={() => handleSubmit()}
        />
      </Box>
    </Box>
  );
};

SignUpModalityForm.propTypes = {
  handleOnNextStep: PropTypes.func.isRequired
};

export default SignUpModalityForm;
