import { grey } from 'theme/colors';

const containerStyle = {
  width: '375px',
  height: '812px',
  margin: '0 auto',
  border: `2px solid ${grey[50]}`,
  borderRadius: '16px',
  overflowY: 'hidden'
};

const smallScreenStyle = {
  marginTop: '24px',
  marginBottom: '24px',
  height: 'calc(100vh - 48px)'
};

const getStyle = (isDesktop, isSmallScreen) => {
  if (isDesktop) {
    if (isSmallScreen) {
      return { ...containerStyle, ...smallScreenStyle };
    }
    return containerStyle;
  }
  return {};
};

export default getStyle;
