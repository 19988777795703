import { Box, useMediaQuery } from '@mui/material';
import { ValidatorBuilder } from 'infra';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StepFormWrapper } from 'UI/components';
import { useUserRecords } from 'UI/contexts';
import { ProgressBar } from '../../progress-bar/progress-bar.component';

const UserCredentialsForm = ({ onSubmit }) => {
  const [activeStep, setActiveStep] = useState(1);
  const { t } = useTranslation('ui');
  const isDesktop = useMediaQuery('(min-width:600px)');
  const { userRecords, setUserRecords } = useUserRecords();
  const fields = {
    name: {
      label: t('signUpUser.input.name.label'),
      type: 'text',
      value: userRecords.name,
      validator: new ValidatorBuilder()
        .nonEmptyString(t('signUpUser.input.name.errorMessage'))
        .build()
    },
    email: {
      label: t('signUpUser.input.email.label'),
      type: 'email',
      value: userRecords.email,
      validator: new ValidatorBuilder()
        .nonEmptyString()
        .emailPattern(t('signUpUser.input.email.errorMessage'))
        .build()
    },
    password: {
      label: t('signUpUser.input.password.label'),
      type: 'password',
      validator: new ValidatorBuilder()
        .nonEmptyString()
        .passwordLength(t('signUpUser.input.password.errorMessage'))
        .build()
    }
  };

  const maxSteps = Object.keys(fields).length;

  const onStepChange = step => {
    setActiveStep(step + 1);
  };

  const handleSubmit = userCredentials => {
    const { name, email } = userCredentials;
    setUserRecords({
      ...userRecords,
      name,
      email
    });
    return onSubmit(userCredentials);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      pt={2}
      pb={9}
      px={3}
    >
      <StepFormWrapper
        fields={fields}
        onSubmit={handleSubmit}
        onStepChange={onStepChange}
        buttonLabel={t('signUpUser.submitButton.label')}
      />
      {!isDesktop && (
        <Box position="fixed" bottom="0" left="0" width="100%">
          <ProgressBar activeStep={activeStep} maxSteps={maxSteps} />
        </Box>
      )}
    </Box>
  );
};

UserCredentialsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default UserCredentialsForm;
