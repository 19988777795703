import validatorPtBrTranslations from '../infra/validators/pt-br.translations.json';
import headerEnTranslations from './components/header/en.translations.json';
import headerPtBrTranslations from './components/header/pt-br.translations.json';
import segmentSearchBarPtBrTranslations from './components/segment-search-bar/pt-br.translations.json';
import preloadFirebaseConfigTranslations from './components/preload-firebase-config-error/pt-br.translations.json';
import signUpCompanyPtBrTranslations from './pages/sign-up/company/pt-br.translations.json';
import signUpUserPtBrTranslations from './pages/sign-up/user/pt-br.translations.json';

const translations = {
  'pt-BR': {
    ui: {
      ...headerPtBrTranslations,
      ...signUpCompanyPtBrTranslations,
      ...signUpUserPtBrTranslations,
      ...validatorPtBrTranslations,
      ...segmentSearchBarPtBrTranslations,
      ...preloadFirebaseConfigTranslations
    }
  },
  en: {
    ui: {
      ...headerEnTranslations
    }
  }
};

export default translations;
