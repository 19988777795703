import { Box, IconButton, Typography } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';
import { ReactComponent as LogoLoggi } from 'assets/logo-loggi.svg';
import { HEADER_VARIANTS } from 'constants/index';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from 'theme/custom-classes';

export const Header = ({ children, onGoBack, variant, goBackLabel }) => {
  const { t } = useTranslation('ui');
  const {
    backButtonLabelColor,
    color,
    backgroundColor,
    borderBottomColor
  } = HEADER_VARIANTS[variant];

  const headerProps = {
    color,
    backgroundColor,
    borderBottomColor
  };
  const classes = useStyles(headerProps);

  return (
    <Box className={classes.header}>
      {onGoBack && (
        <IconButton
          color="inherit"
          fontSize="small"
          style={{ padding: '0px' }}
          onClick={onGoBack}
        >
          <ArrowBackIos color="inherit" />
          <Box color={backButtonLabelColor} mx={0.5}>
            <Typography style={{ lineHeight: 'unset' }} ml={12}>
              {goBackLabel || t('header.goBackButtonLabel')}
            </Typography>
          </Box>
        </IconButton>
      )}
      <LogoLoggi height="24px" />
      {children}
    </Box>
  );
};

Header.defaultProps = {
  children: null,
  onGoBack: null,
  variant: 'primary',
  goBackLabel: null
};

Header.propTypes = {
  children: PropTypes.node,
  onGoBack: PropTypes.func,
  variant: PropTypes.oneOf(Object.keys(HEADER_VARIANTS)),
  goBackLabel: PropTypes.string
};

export default Header;
