import signUpPtBrTranslations from './sign-up/pt-br.translations.json';

const translations = {
  'pt-BR': {
    containers: {
      ...signUpPtBrTranslations
    }
  }
};

export default translations;
