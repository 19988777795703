const STEP_PHONE = 'phone';
const STEP_CPF = 'cpf';
const STEP_CNPJ = 'cnpj';
const STEP_CEP = 'cep';
const STEP_SEGMENT = 'segment';
const STEP_PACKAGES = 'estimatedMonthlyPackages';
const STEP_TICKET = 'averageTicket';
const STEP_INTEGRATOR = 'integrator';
const STEP_TERMS_AND_CONDITIONS = 'termsAndConditions';

const ORDENATED_STEPS_LIST = [
  STEP_PHONE,
  STEP_CPF,
  STEP_CNPJ,
  STEP_CEP,
  STEP_SEGMENT,
  STEP_PACKAGES,
  STEP_TICKET,
  STEP_INTEGRATOR,
  STEP_TERMS_AND_CONDITIONS
];

export {
  ORDENATED_STEPS_LIST,
  STEP_PHONE,
  STEP_CPF,
  STEP_CNPJ,
  STEP_CEP,
  STEP_SEGMENT,
  STEP_PACKAGES,
  STEP_TICKET,
  STEP_INTEGRATOR,
  STEP_TERMS_AND_CONDITIONS
};
