import { Box, Slide, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, InfiniteLoading } from 'UI/components';
import getStyle from 'UI/pages/utils/utils';
import UserCredentialsForm from './form/user-credentials-form.component';
import { STEP_MODALITY, STEP_FORM_USER } from './sign-up-user.constants';
import SignUpModalityForm from './form/signup-modality/signup-modality-form.component';

export const USER_SIGNUP_FORM_ID = 'user-signup-form';
export const SIGNUP_FORM = 'signup-form';
export const USER_SIGNUP_LOADING_ID = 'user-signup-loading';
export const SIGNUP_MODALITY_FORM_ID = 'sign-up-modality-form';

const SLIDE_TRANSITION_TIMEOUT = 640;
const ssoUrl = process.env.REACT_APP_SSO_URL;

const SignUpUser = ({ onSubmit, hasSignedUp }) => {
  const { t } = useTranslation('ui');
  const isSmallScreen = useMediaQuery('(max-height:812px)');
  const isDesktop = useMediaQuery('(min-width:600px)');
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(STEP_MODALITY);

  const handleSubmit = async userCredentials => {
    setLoading(true);
    const result = await onSubmit(userCredentials);
    if (!result) setLoading(false);
  };

  const handleOnGoBack = () => {
    if (currentStep === STEP_MODALITY) {
      window.location.href = ssoUrl;
      return;
    }
    if (currentStep === STEP_FORM_USER) {
      setCurrentStep(STEP_MODALITY);
    }
  };

  const handleOnNextStep = () => {
    return setCurrentStep(STEP_FORM_USER);
  };

  const stepsComponentsTable = {
    [STEP_FORM_USER]: (
      <UserCredentialsForm
        onSubmit={handleSubmit}
        data-testid={USER_SIGNUP_FORM_ID}
      />
    ),
    [STEP_MODALITY]: (
      <SignUpModalityForm
        handleOnNextStep={handleOnNextStep}
        data-testid={SIGNUP_MODALITY_FORM_ID}
      />
    )
  };

  const getSignUpStepContent = () => {
    return stepsComponentsTable[currentStep] || <></>;
  };

  return (
    <Box style={getStyle(isDesktop, isSmallScreen)}>
      <Slide
        direction="left"
        in={!loading && !hasSignedUp}
        timeout={{
          appear: SLIDE_TRANSITION_TIMEOUT,
          enter: SLIDE_TRANSITION_TIMEOUT,
          exit: SLIDE_TRANSITION_TIMEOUT / 2
        }}
        unmountOnExit
      >
        <Box height="100vh">
          <Header
            style={{ textAlign: 'center' }}
            onGoBack={handleOnGoBack}
            goBackLabel={t('header.signUp')}
            data-testid={SIGNUP_FORM}
          />
          {getSignUpStepContent()}
        </Box>
      </Slide>
      <Slide
        direction="left"
        in={loading}
        mountOnEnter
        timeout={SLIDE_TRANSITION_TIMEOUT}
        unmountOnExit
      >
        <Box
          data-testid={USER_SIGNUP_LOADING_ID}
          display="flex"
          height={isDesktop ? '100%' : '100vh'}
          justifyContent="center"
        >
          <InfiniteLoading
            title={t('signUpUser.loadingScreen.title')}
            description={t('signUpUser.loadingScreen.description')}
          />
        </Box>
      </Slide>
    </Box>
  );
};

SignUpUser.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  hasSignedUp: PropTypes.bool.isRequired
};

export default SignUpUser;
