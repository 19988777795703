import mar from '@loggi/mar';
import merge from 'lodash/merge';
import { blue, red, root } from './colors';
import { shadow } from './shadows';

export default merge(mar.overrides, {
  MuiAlert: {
    styleOverrides: {
      root: {
        alignItems: 'center',
        boxShadow: shadow.standard,
        borderRadius: '10px',
        width: '100%'
      },
      filledInfo: {
        backgroundColor: root[0],
        color: root[900],
        '& .MuiAlert-action': {
          color: blue[500],
          '& .MuiButton-text': {
            padding: '14px 20px'
          }
        },
        fontWeight: 400
      }
    }
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        '&$focused': {
          color: blue[500]
        }
      }
    }
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: '8px',
        caretColor: blue[500],
        '&$focused $notchedOutline': {
          borderColor: blue[500]
        },
        '& .MuiOutlinedInput-notchedOutline': {
          transition: 'border-color 400ms',
          willChange: 'border-color'
        },
        '&.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
          borderColor: blue[500]
        },
        '&:hover:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
          borderColor: blue[500]
        },
        '&.Mui-error': {
          caretColor: red[500]
        }
      }
    }
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        '&.MuiInputLabel-outlined.Mui-focused': {
          color: root[900]
        }
      }
    }
  },
  MuiTextField: {
    variants: [
      {
        props: { className: 'blank' },
        style: {
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          }
        }
      }
    ]
  }
});
