import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, CssBaseline } from '@mui/material';
import { preloadFirebaseConfigs } from '@loggi/components/src/one/remote-config';
import applyGoogleTranslatorMonkeyPatch from 'configuration/apply-google-translator.monkey-patch';
import { PreloadFirebaseConfigError } from 'UI/components';
import { AppRouter } from 'routes';

function App() {
  const [firebaseLoading, setFirebaseLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    preloadFirebaseConfigs()
      .then(() => {
        applyGoogleTranslatorMonkeyPatch();
        setFirebaseLoading(false);
      })
      .catch(() => {
        setIsError(true);
      });
  }, []);

  const onTryAgain = () => {
    window.location.reload();
  };

  if (isError) {
    return (
      <>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100vh"
          mx={3}
        >
          <PreloadFirebaseConfigError onTryAgain={onTryAgain} />
        </Box>
      </>
    );
  }

  if (firebaseLoading) {
    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        minHeight="100vh"
      >
        <CircularProgress data-testid="firebase-preloading" />
      </Box>
    );
  }

  return (
    <>
      <CssBaseline />
      <Box height="100%" pt={0}>
        <AppRouter />
      </Box>
    </>
  );
}

export default App;
