import { useAmplifyAuth } from '@loggi/authentication-lib';

const useCurrentUser = () => {
  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();

  if (!authenticatedUser) return null;

  const userAccess = JSON.parse(
    authenticatedUser['custom:access'] ||
      authenticatedUser?.challengeParam?.userAttributes['custom:access'] ||
      null
  );
  const companyAccess = userAccess?.companies || [];

  return { ...authenticatedUser, companyAccess };
};

export default useCurrentUser;
