import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { authenticationLocales } from '@loggi/authentication';
import errorBoundary from 'locales/one-error-boundary-pt-br.translations';
import containerTranslations from '../containers/translations';
import uiTranslations from '../UI/translations';

/**
 * i18next configuration
 */

i18n.use(initReactI18next).init({
  resources: {
    'pt-BR': {
      ...authenticationLocales.ptBr,
      ...containerTranslations['pt-BR'],
      ...uiTranslations['pt-BR'],
      one: { ...errorBoundary }
    }
  },
  lng: 'pt-BR',
  fallbackLng: 'pt-BR'
});

export default i18n;
