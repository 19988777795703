import { Box, SwipeableDrawer, Typography } from '@mui/material';
import { noop } from 'lodash';
import { isAndroid } from 'mobile/native.helpers';
import PropTypes from 'prop-types';
import React from 'react';
import { root } from 'theme/colors';
import { ButtonsGrid } from '../buttons-grid/buttons-grid.component';
import { Heading } from './heading/heading.component';

const getHeight = (fullHeight = false) => {
  if (!fullHeight) return 'auto';
  if (isAndroid()) return '80%';

  return 'calc(100% - 40px - env(safe-area-inset-top))';
};

const BaseDrawer = ({
  alignTitleLeft,
  children,
  description,
  disabled,
  fluid,
  fullHeight,
  hasPuller,
  illustration,
  isLoading,
  isOpen,
  labels,
  onClose,
  onConfirm,
  onDismiss,
  title,
  variant,
  vertical
}) => {
  const isTemporary = variant === 'temporary';
  const onCloseHandler = (event = {}) => {
    const { target } = event;
    if (target?.classList.contains('MuiBackdrop-root')) return null;
    if (!isTemporary) return onDismiss(event);
    return onClose(event);
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      onClose={onCloseHandler}
      onOpen={noop}
      open={isOpen}
      PaperProps={{
        style: {
          backgroundColor: isTemporary ? 'transparent' : 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          height: '100%',
          justifyContent: 'flex-end',
          overflowY: 'hidden'
        }
      }}
      variant={variant}
    >
      <Box
        alignItems={alignTitleLeft ? 'flex-start' : 'center'}
        bgcolor={root[0]}
        borderRadius="12px 12px 0 0"
        color={root[900]}
        display="flex"
        flexDirection="column"
        height={getHeight(fullHeight)}
        justifyContent="space-between"
        p={fluid ? 0 : 3}
        pt={2}
      >
        <Heading hasPuller={hasPuller} illustration={illustration} />
        <Typography
          align={alignTitleLeft ? 'left' : 'center'}
          variant="subtitle1"
        >
          {title}
        </Typography>
        <Box mt={2}>
          <Typography align="center" variant="body2">
            {description}
          </Typography>
        </Box>
        {children}
        <ButtonsGrid
          disabled={disabled}
          isLoading={isLoading}
          labels={labels}
          onConfirm={onConfirm}
          onDismiss={onDismiss}
          vertical={vertical}
        />
      </Box>
    </SwipeableDrawer>
  );
};

BaseDrawer.defaultProps = {
  alignTitleLeft: false,
  children: null,
  description: '',
  disabled: false,
  fluid: false,
  fullHeight: false,
  hasPuller: false,
  illustration: null,
  isLoading: false,
  labels: {
    confirm: '',
    dismiss: ''
  },
  onClose: noop,
  onConfirm: noop,
  onDismiss: noop,
  title: '',
  variant: 'persistent',
  vertical: false
};

BaseDrawer.propTypes = {
  alignTitleLeft: PropTypes.bool,
  children: PropTypes.node,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  hasPuller: PropTypes.bool,
  fluid: PropTypes.bool,
  fullHeight: PropTypes.bool,
  illustration: PropTypes.node,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  labels: PropTypes.shape({
    confirm: PropTypes.string,
    dismiss: PropTypes.string
  }),
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  onDismiss: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  variant: PropTypes.oneOf(['permanent', 'persistent', 'temporary']),
  vertical: PropTypes.bool
};

export default BaseDrawer;
