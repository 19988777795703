import { Box } from '@mui/material';
import { ValidatorBuilder } from 'infra';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { StepFormWrapper } from 'UI/components';
import { useUserRecords } from 'UI/contexts';

const AverageTicketStepForm = ({ handleOnNextStep }) => {
  const { t } = useTranslation('ui');
  const { userRecords, setUserRecords } = useUserRecords();

  const fields = {
    averageTicket: {
      label: t('signUpCompany.input.averageTicket.label'),
      type: 'average-ticket',
      value: userRecords.averageTicket,
      validator: new ValidatorBuilder()
        .averageTicketPattern(
          t('signUpCompany.input.averageTicket.errorMessage')
        )
        .build()
    }
  };

  const handleSubmit = userCredentials => {
    const { averageTicket } = userCredentials;
    setUserRecords({ ...userRecords, averageTicket });
    handleOnNextStep();
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      pt={2}
      pb={9}
      px={3}
    >
      <StepFormWrapper
        fields={fields}
        onSubmit={handleSubmit}
        buttonLabel={t('signUpCompany.submitButton.label')}
      />
    </Box>
  );
};

AverageTicketStepForm.propTypes = {
  handleOnNextStep: PropTypes.func.isRequired
};

export default AverageTicketStepForm;
