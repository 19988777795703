import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Box,
  Checkbox,
  Divider,
  styled,
  Typography,
  useMediaQuery
} from '@mui/material';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import { sortedInputOptions } from '@loggi/components/src/one/segments';
import { blue, grey } from 'theme/colors';
import { FORMS_VARIANTS } from 'constants/index';
import { Button, SegmentSearchBar } from 'UI/components';
import { useUserRecords } from 'UI/contexts';

const StepLabel = styled(Typography)(({ theme }) => ({
  transition: 'font-size 400ms',
  willChange: 'font-size',
  fontSize: '22px',
  '&.--display': {
    fontSize: '14px',
    display: 'flex'
  },
  '&, &.--display': {
    color: theme
  }
}));

const CustomCheckbox = styled(Checkbox)({
  padding: '0px',
  '&:hover': {
    backgroundColor: 'transparent',
    color: blue[500]
  }
});

const FixedBottomBox = styled(Box)({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0
});

const SegmentStepForm = ({ handleOnNextStep }) => {
  const { t } = useTranslation('ui');
  const isDesktop = useMediaQuery('(min-width:600px)');
  const isSmallScreen = useMediaQuery('(max-height:812px)');
  const { userRecords, setUserRecords } = useUserRecords();
  const [filteredSegments, setFilteredSegments] = useState([
    ...sortedInputOptions
  ]);
  const [checkedSegment, setCheckedSegment] = useState(null);
  const { background, color } = FORMS_VARIANTS.primary;

  useEffect(() => {
    setCheckedSegment(userRecords.segment);
  }, [userRecords.segment]);

  const handleNextStep = () => {
    setUserRecords({ ...userRecords, segment: checkedSegment });
    handleOnNextStep();
  };

  const scrollBarStyle = `
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

  const fixedSmallScreenBottomStyle = {
    position: 'fixed',
    width: '373px',
    bottom: 0,
    left: '50%',
    right: '50%',
    transform: 'translate(-50%, -28%)',
    backgroundColor: 'white',
    borderRadius: '16px'
  };

  const renderSegmentOptions = () =>
    filteredSegments.map(({ label, value }) => (
      <Box key={label}>
        <Box
          py={3}
          onClick={() =>
            setCheckedSegment(checkedSegment === value ? null : value)
          }
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {label}
            <CustomCheckbox
              size="small"
              data-testid="segment-checkbox"
              inputProps={{
                'data-testid': `checkbox-${value}`
              }}
              checked={checkedSegment === value}
              icon={<CircleUnchecked />}
              checkedIcon={<CircleCheckedFilled />}
            />
          </Box>
        </Box>
        <Box>
          <Divider style={{ backgroundColor: grey[50] }} />
        </Box>
        <style>{scrollBarStyle}</style>
      </Box>
    ));

  const getListHeight = () => {
    if (isDesktop) {
      if (isSmallScreen) {
        return 'calc(55vh - 80px)';
      }
      return '475px';
    }
    return '';
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      pt={2}
      pb={9}
      px={3}
    >
      <Box pb={3}>
        <StepLabel
          className="--display"
          color="textPrimary"
          htmlFor="segment"
          variant="subtitle2"
          data-testid="segment-step-input-label"
          theme="primary"
          variantMapping={{ subtitle2: 'label' }}
        >
          {t('signUpCompany.input.segment.label')}
        </StepLabel>
      </Box>
      <SegmentSearchBar
        segments={sortedInputOptions}
        setFilteredSegments={setFilteredSegments}
      />
      <Box style={{ overflow: 'auto' }} height={getListHeight()} mb={3}>
        {renderSegmentOptions()}
      </Box>
      {isDesktop && (
        <Box
          style={isSmallScreen ? fixedSmallScreenBottomStyle : {}}
          p={isSmallScreen ? 3 : 0}
        >
          <Button
            fullWidth
            disabled={!checkedSegment}
            bgColor={background}
            label={t('signUpCompany.submitButton.label')}
            onClick={handleNextStep}
            textColor={color}
          />
        </Box>
      )}
      {!isDesktop && (
        <FixedBottomBox p={3} bgcolor="white">
          <Button
            fullWidth
            disabled={!checkedSegment}
            bgColor={background}
            label={t('signUpCompany.submitButton.label')}
            onClick={handleNextStep}
            textColor={color}
          />
        </FixedBottomBox>
      )}
    </Box>
  );
};

SegmentStepForm.propTypes = {
  handleOnNextStep: PropTypes.func.isRequired
};

export default SegmentStepForm;
