import { Box, Typography } from '@mui/material';
import loadingAnimation from 'assets/lotties/linear-undetermined-loading.json';
import PropTypes from 'prop-types';
import React from 'react';
import Lottie from 'react-lottie';

const InfiniteLoading = ({ title, description }) => {
  return (
    <Box
      alignItems="center"
      alignSelf="center"
      display="flex"
      flexDirection="column"
      width="100%"
    >
      <Box maxWidth="300px">
        <Typography align="center" variant="subtitle1">
          {title}
        </Typography>
        {description && (
          <Box marginTop={3}>
            <Typography align="center" variant="subtitle2">
              {description}
            </Typography>
          </Box>
        )}
        <Box mt="-96px">
          <Lottie
            options={{
              loop: true,
              animationData: loadingAnimation,
              rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
            }}
            height="300px"
            isClickToPauseDisabled
          />
        </Box>
      </Box>
    </Box>
  );
};

InfiniteLoading.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string
};

InfiniteLoading.defaultProps = { description: '' };

export default InfiniteLoading;
