import { Box, Slide, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, InfiniteLoading } from 'UI/components';
import { useCurrentUser } from 'auth';
import { useUserRecords } from 'UI/contexts';
import getStyle from 'UI/pages/utils/utils';
import CpfStepForm from './form/natural-person/cpf/cpf-step-form.component';
import PhoneStepForm from './form/natural-person/phone/phone-step-form.component';
import CnpjStepForm from './form/legal-person/cnpj/cnpj-step-form.component';
import CepStepForm from './form/legal-person/cep/cep-step-form.component';
import SegmentStepForm from './form/legal-person/segment/segment-step-form.component';
import EstimatedMonthlyPackagesStepForm from './form/legal-person/estimated-monthly-packages/estimated-monthly-packages-step-form.component';
import AverageTicketStepForm from './form/legal-person/average-ticket/average-ticket-step-form.component';
import IntegratorStepForm from './form/legal-person/integrator/integrator-step-form.component';
import TermsAndConditionsStepForm from './form/legal-person/terms-and-conditions/terms-and-conditions-step-form.component';

import {
  ORDENATED_STEPS_LIST,
  STEP_PHONE,
  STEP_CPF,
  STEP_CNPJ,
  STEP_CEP,
  STEP_SEGMENT,
  STEP_PACKAGES,
  STEP_TICKET,
  STEP_INTEGRATOR,
  STEP_TERMS_AND_CONDITIONS
} from './sign-up-company.constants';

export const COMPANY_SIGNUP_FORM_ID = 'company-signup-form';
export const COMPANY_SIGNUP_LOADING_ID = 'company-signup-loading';
export const COMPANY_SIGNUP_SUCCESS_ID = 'company-signup-success';

const SLIDE_TRANSITION_TIMEOUT = 640;

const SignUpCompany = ({ onGoBack, onSubmit }) => {
  const { t } = useTranslation('ui');
  const isSmallScreen = useMediaQuery('(max-height:812px)');
  const isDesktop = useMediaQuery('(min-width:600px)');
  const user = useCurrentUser();
  const [isCompanyDataFormSubmitted, setIsCompanyDataFormSubmitted] = useState(
    false
  );
  const [loading, setLoading] = useState(false);
  const { userRecords } = useUserRecords();
  const [currentStep, setCurrentStep] = useState(STEP_PHONE);

  const handleSubmit = async userCredentials => {
    setIsCompanyDataFormSubmitted(true);
    setLoading(true);
    try {
      await onSubmit(userCredentials);
    } catch {
      setIsCompanyDataFormSubmitted(false);
    }
    setLoading(false);
  };

  const handleOnNextStep = () => {
    if (currentStep === STEP_CPF && userRecords.isNaturalPersonSignUp) {
      return setCurrentStep(STEP_TERMS_AND_CONDITIONS);
    }
    const currentIndex = ORDENATED_STEPS_LIST.indexOf(currentStep);
    return setCurrentStep(ORDENATED_STEPS_LIST[currentIndex + 1]);
  };

  const handleOnGoBack = () => {
    if (currentStep === STEP_PHONE) return onGoBack();
    if (
      currentStep === STEP_TERMS_AND_CONDITIONS &&
      userRecords.isNaturalPersonSignUp
    ) {
      return setCurrentStep(STEP_CPF);
    }
    const currentIndex = ORDENATED_STEPS_LIST.indexOf(currentStep);
    return setCurrentStep(ORDENATED_STEPS_LIST[currentIndex - 1]);
  };

  const stepsComponentsTable = {
    [STEP_PHONE]: <PhoneStepForm handleOnNextStep={handleOnNextStep} />,
    [STEP_CPF]: <CpfStepForm handleOnNextStep={handleOnNextStep} />,
    [STEP_CNPJ]: <CnpjStepForm handleOnNextStep={handleOnNextStep} />,
    [STEP_CEP]: <CepStepForm handleOnNextStep={handleOnNextStep} />,
    [STEP_SEGMENT]: <SegmentStepForm handleOnNextStep={handleOnNextStep} />,
    [STEP_PACKAGES]: (
      <EstimatedMonthlyPackagesStepForm handleOnNextStep={handleOnNextStep} />
    ),
    [STEP_TICKET]: (
      <AverageTicketStepForm handleOnNextStep={handleOnNextStep} />
    ),
    [STEP_INTEGRATOR]: (
      <IntegratorStepForm handleOnNextStep={handleOnNextStep} />
    ),
    [STEP_TERMS_AND_CONDITIONS]: (
      <TermsAndConditionsStepForm onSubmit={handleSubmit} user={user} />
    )
  };

  const getSignUpStepContent = () => {
    return stepsComponentsTable[currentStep] || <></>;
  };

  return (
    <Box style={getStyle(isDesktop, isSmallScreen)}>
      <Slide
        direction="left"
        in={!isCompanyDataFormSubmitted}
        timeout={SLIDE_TRANSITION_TIMEOUT}
        unmountOnExit
      >
        <Box height="100vh">
          <Header
            onGoBack={handleOnGoBack}
            goBackLabel={
              currentStep !== STEP_PHONE
                ? t('header.goBackButtonLabel')
                : t('header.logout')
            }
          />
          {getSignUpStepContent()}
        </Box>
      </Slide>
      <Slide
        direction="left"
        in={loading}
        mountOnEnter
        timeout={SLIDE_TRANSITION_TIMEOUT}
        unmountOnExit
      >
        <Box
          data-testid={COMPANY_SIGNUP_LOADING_ID}
          display="flex"
          justifyContent="center"
          height="100vh"
          px={3}
          py={6}
        >
          <InfiniteLoading
            title={t('signUpCompany.loadingScreen.title')}
            description={t('signUpCompany.loadingScreen.description')}
          />
        </Box>
      </Slide>
    </Box>
  );
};

SignUpCompany.propTypes = {
  onGoBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default SignUpCompany;
