import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { grey, root } from 'theme/colors';
import { shadow } from 'theme/shadows';

export const onClickPropType = (props, ...args) => {
  if (!props.href && !props.onClick && !props.to) {
    return new Error('Button should have onClick prop');
  }
  return PropTypes.func(props, ...args);
};

export const dimensions = {
  lg: {
    borderRadius: '12px',
    fontSize: '16px',
    height: '56px',
    iconSize: '22',
    loaderSize: '24px'
  },
  md: {
    borderRadius: '12px',
    fontSize: '14px',
    height: '48px',
    iconSize: '20',
    loaderSize: '24px'
  },
  sm: {
    borderRadius: '8px',
    fontSize: '12px',
    height: '32px',
    iconSize: '14',
    loaderSize: '16px'
  }
};

export const getComponent = (href, to) => {
  if (to) return Link;
  if (href) return 'a';
  return 'button';
};

export const getDisabledStyle = (disabled, variant) => {
  if (!disabled) return null;
  if (variant === 'outlined') {
    return { borderColor: grey[200], color: grey[200] };
  }
  return { backgroundColor: grey[50], color: grey[200] };
};

export const getBoxShadow = (disabled, variant) =>
  !disabled && variant === 'contained' ? { boxShadow: shadow.standard } : null;

export const getStylesByVariant = (
  backgroundColor,
  borderColor,
  borderWidth,
  color,
  disabled,
  variant
) => {
  if (disabled) return { borderStyle: 'solid', color: grey[200] };
  const styles = {
    contained: { backgroundColor, border: 'none', color },
    outlined: {
      backgroundColor: 'transparent',
      border: `${borderWidth}px solid ${borderColor || color}`,
      color
    },
    'outlined-neutral': {
      backgroundColor: 'transparent',
      border: `${borderWidth}px solid ${grey[100]}`,
      color: root[900]
    },
    text: { backgroundColor: 'transparent', border: 'none', color }
  };
  return get(styles, variant, styles.contained);
};

export const getMuiVariant = ({ variant }) => {
  const muiVariantsByVariant = { 'outlined-neutral': 'outlined' };
  return get(muiVariantsByVariant, variant, variant);
};

export const getLabelStyle = ({ alignment }) => {
  return alignment === 'left'
    ? {
        display: 'block',
        overflow: 'hidden',
        textAlign: 'left',
        textOverflow: 'ellipsis',
        width: '100%'
      }
    : {};
};
