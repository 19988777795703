import { loggiWebApi } from '@loggi/authentication-lib';

const ACCOUNTS_COMPANIES_ENDPOINT = '/one/api/accounts/v1/companies/';

const formatCompanyPayload = companyData => {
  const {
    email,
    phone,
    cpf,
    cep,
    cnpj,
    averageTicket,
    sharedName,
    noL4BStatus,
    integrators,
    otherIntegrator,
    segment,
    estimatedMonthlyPackages,
    isNaturalPersonSignUp
  } = companyData;
  let payload;
  if (isNaturalPersonSignUp) {
    payload = { cpf, landline_1: phone };
  } else {
    payload = {
      cpf,
      cnpj,
      landline_1: phone,
      shared_name: sharedName,
      company_configuration_json: {
        segment,
        average_ticket: Number(averageTicket),
        estimated_monthly_packages: estimatedMonthlyPackages,
        postal_code: cep,
        owner_email: email,
        is_no_l4b_status: noL4BStatus
      }
    };
    if (integrators.length || otherIntegrator) {
      payload.company_configuration_json.integrators = otherIntegrator
        ? [...integrators, otherIntegrator]
        : integrators;
    }
  }
  return payload;
};

const companiesAccountApi = {
  signupCompany: payload => {
    const formattedPayload = formatCompanyPayload(payload);

    return loggiWebApi
      .url(ACCOUNTS_COMPANIES_ENDPOINT)
      .post(formattedPayload)
      .json();
  },
  validateCompanyData: (sharedName, cnpj) =>
    loggiWebApi
      .url(`${ACCOUNTS_COMPANIES_ENDPOINT}validate/`)
      .post({ shared_name: sharedName, cnpj })
      .json()
};

export default companiesAccountApi;
