import { Box } from '@mui/material';
import { ValidatorBuilder } from 'infra';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StepFormWrapper } from 'UI/components';
import { useUserRecords } from 'UI/contexts';

const CpfStepForm = ({ handleOnNextStep }) => {
  const { t } = useTranslation('ui');
  const { userRecords, setUserRecords } = useUserRecords();

  const fields = {
    cpf: {
      label: t('signUpCompany.input.cpf.label'),
      type: 'cpf',
      value: userRecords.cpf,
      validator: new ValidatorBuilder()
        .nonEmptyString()
        .cpfPattern(t('signUpCompany.input.cpf.errorMessage'))
        .build()
    }
  };

  const handleSubmit = userCredentials => {
    const { cpf } = userCredentials;
    setUserRecords({ ...userRecords, cpf });
    handleOnNextStep();
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      pt={2}
      pb={9}
      px={3}
    >
      <StepFormWrapper
        fields={fields}
        onSubmit={handleSubmit}
        buttonLabel={t('signUpCompany.submitButton.label')}
      />
    </Box>
  );
};

CpfStepForm.propTypes = {
  handleOnNextStep: PropTypes.func.isRequired
};

export default CpfStepForm;
