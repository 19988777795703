import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';

export const UserRecordsContext = createContext({});

export function UserRecordsContextProvider({ children }) {
  const [userRecords, setLocalUserRecords] = useState({
    name: '',
    email: '',
    phone: '',
    cpf: '',
    cep: '',
    cnpj: '',
    averageTicket: '',
    sharedName: '',
    noL4BStatus: false,
    integrators: [],
    otherIntegrator: '',
    segment: null,
    estimatedMonthlyPackages: '',
    isNaturalPersonSignUp: false
  });

  const setUserRecords = useCallback(
    updatedUserRecords => {
      if (!isEqual(userRecords, updatedUserRecords))
        setLocalUserRecords(updatedUserRecords);
    },
    [userRecords]
  );

  const providerValue = useMemo(() => ({ userRecords, setUserRecords }), [
    userRecords,
    setUserRecords
  ]);

  return (
    <UserRecordsContext.Provider value={providerValue}>
      {children}
    </UserRecordsContext.Provider>
  );
}

UserRecordsContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useUserRecords = () => {
  return useContext(UserRecordsContext);
};
