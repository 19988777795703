/* eslint-disable prefer-promise-reject-errors */
import { generateRandomString as generateUUID } from '@aws-amplify/core';

const ANDROID_BRIDGE_METHOD_NOT_FOUND_ERROR = 'Method not found';

/** @type {{promiseId: {resolve: (value) => void, reject: (reason?) => void}}} */
const promises = {};

// these functions are called by native methods
function resolvePromise(promiseId, value) {
  promises[promiseId].resolve(value);
  delete promises[promiseId];
}
function rejectPromise(promiseId, reason) {
  promises[promiseId].reject(reason);
  delete promises[promiseId];
}
window.resolvePromise = resolvePromise;
window.rejectPromise = rejectPromise;

const hasIOSWebInterface = !!window.webkit?.messageHandlers;
const hasAndroidWebInterface = !!window.BeyondWebInterface;

export const hasWebViewInterface = () =>
  hasIOSWebInterface || hasAndroidWebInterface;

export const hasEventRegistered = eventName =>
  !!window.webkit?.messageHandlers?.[eventName] ||
  !!window.BeyondWebInterface?.hasEvent?.(eventName);

export const hasCreateNativeCorpOrder = () =>
  hasEventRegistered('switchToNativeApp') &&
  hasEventRegistered('fillNativeCorpOrder');

const handlers = {
  noop: () => {},
  iOS: (event, message, promiseId) => {
    const payload = JSON.stringify({ promiseId, ...message });
    window.webkit.messageHandlers[event]?.postMessage(payload);
  },
  Android: (event, message, promiseId) => {
    const payload = JSON.stringify(message);
    // attemp to send payload in new format (with JS promise support)
    try {
      window.BeyondWebInterface?.sendEvent(event, payload, promiseId);
    } catch (err) {
      if (err.message === ANDROID_BRIDGE_METHOD_NOT_FOUND_ERROR)
        window.BeyondWebInterface?.sendEvent(event, payload);
      else throw err;
    }
  }
};

const nativeAppEventBridge =
  (hasIOSWebInterface && handlers.iOS) ||
  (hasAndroidWebInterface && handlers.Android) ||
  handlers.noop;

const nativeAppEventSender = (event, message) => {
  return new Promise((resolve, reject) => {
    const promiseId = generateUUID();
    promises[promiseId] = { resolve, reject };
    nativeAppEventBridge(event, message, promiseId);
  });
};

/** @param {{idToken: String, hasBeyondAccess: Boolean}} payload */
export const switchToNativeApp = payload =>
  nativeAppEventSender('switchToNativeApp', payload);

/** @typedef {{lat, lng, description, complement, addressComponents: []}} Address */
/**
 * @param {{
 *  idToken: String,
 *  hasBeyondAccess: Boolean,
 *  pickup: { address: Address, phone: String, instructionText: String },
 *  delivery: { address: Address, phone: String, instructionText: String }
 * }} payload
 */
export const createNativeCorpOrder = payload => {
  const { idToken, hasBeyondAccess } = payload;
  const { pickup, delivery } = payload;

  return nativeAppEventSender('switchToNativeApp', {
    idToken,
    hasBeyondAccess
  })
    .then(() =>
      nativeAppEventSender('fillNativeCorpOrder', { pickup, delivery })
        .then(value => value ?? {}) // ensure compatability with older native devices: expected Object but they answer undefined)
        .catch(error =>
          Promise.reject({ error, origin: 'fillNativeCorpOrder' })
        )
    )
    .catch(error => {
      if (error.origin) return Promise.reject(error); // if already treated by fillNativeCorpOrder.catch(), don't overwrite it
      return Promise.reject({ error, origin: 'switchToNativeApp' });
    });
};

/**
 * Copy a text to clipboard using native implementation
 *
 * @param {String} text
 * @returns {Promise}
 */
export const copyToClipboardNative = text =>
  nativeAppEventSender('copyToClipboard', { text });

export default nativeAppEventSender;
