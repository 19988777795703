import { blue, grey, root } from 'theme/colors';

export const COLOR_VARIANTS = { PRIMARY: 'primary', WHITE: 'white' };

export const HEADER_VARIANTS = {
  [COLOR_VARIANTS.PRIMARY]: {
    color: blue[500],
    backButtonLabelColor: root[900],
    backgroundColor: root[0],
    borderBottomColor: 'rgb(204, 204, 204, 0.6)' // grey[500] with 0.6 opacity
  },
  [COLOR_VARIANTS.WHITE]: {
    color: root[0],
    backButtonLabelColor: root[0],
    backgroundColor: blue[500],
    borderBottomColor: 'rgb(0, 0, 0, 0.2)'
  }
};

export const ICON_VARIANTS = {
  [COLOR_VARIANTS.PRIMARY]: {
    color: blue[500]
  },
  [COLOR_VARIANTS.WHITE]: {
    color: root[0]
  }
};

export const FORMS_VARIANTS = {
  [COLOR_VARIANTS.PRIMARY]: {
    background: blue[500],
    color: root[0],
    text: root[900],
    optionBorder: grey[50],
    optionIcon: grey[300]
  },
  [COLOR_VARIANTS.WHITE]: {
    background: root[0],
    color: blue[500],
    text: root[0],
    optionBorder: `${root[900]}40`,
    optionIcon: root[0]
  }
};
