/** This is purposely here so sentry is started as soon as possible
 * so we don't miss any errors on the app first render */
import { AmplifyAuthProvider } from '@loggi/authentication-lib';
import { AppErrorBoundary } from '@loggi/components/src/one';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'configuration/amplify-init';
import 'configuration/firebase-init';
import 'configuration/hotjar-init';
import 'configuration/i18n-init';
import 'configuration/tag-manager-init';
import { SnackbarProvider } from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'sentry/sentry-init';

import { NativeAppEventHandlerProvider } from 'mobile';
import theme from 'theme';
import App from './App';
import { DomainUseCasesContext, UserRecordsContextProvider } from './contexts';

const queryClient = new QueryClient();

function RootComponent({ useCases }) {
  return (
    <DomainUseCasesContext.Provider value={useCases}>
      <QueryClientProvider client={queryClient}>
        <UserRecordsContextProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <AppErrorBoundary
                beforeCapture={scope => {
                  scope.setTag('app.region', 'one');
                }}
              >
                <SnackbarProvider
                  anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                  autoHideDuration={7000}
                  maxSnack={3}
                >
                  <AmplifyAuthProvider>
                    <NativeAppEventHandlerProvider>
                      <Router>
                        <App />
                      </Router>
                    </NativeAppEventHandlerProvider>
                  </AmplifyAuthProvider>
                </SnackbarProvider>
              </AppErrorBoundary>
            </ThemeProvider>
          </StyledEngineProvider>
        </UserRecordsContextProvider>
      </QueryClientProvider>
    </DomainUseCasesContext.Provider>
  );
}

RootComponent.propTypes = {
  useCases: PropTypes.shape({}).isRequired
};

export default RootComponent;
